<template>
  <section class="admin-account-info">
    <v-container class="custom-container">
      <div class="textbox">
        <h1>{{ $t('langkey.account-information') }}</h1>
        <p class="description">
          {{ $t('langkey.admin-account-info-description') }}
        </p>
      </div>

      <v-card
        rounded="sm"
        class="account-info"
      >
        <div class="user-information">
          <div class="title-header">
            <h6>{{ $t('langkey.user-information') }}</h6>
            <v-btn
              icon
              plain
            >
              <icon-edit />
            </v-btn>
          </div>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.email') }} :
            </p>
            <p
              v-if="userInformation"
              class="value"
            >
              {{ userInformation.email }}
            </p>
          </div>
          <div class="row-details">
            <p class="label">
              {{ $t('langkey.username') }} :
            </p>
            <p
              v-if="userInformation"
              class="value"
            >
              {{ userInformation.username }}
            </p>
          </div>
        </div>

        <div class="change-password">
          <div class="title-header">
            <h6>{{ $t('langkey.change-password') }}</h6>
            <v-btn
              icon
              plain
            >
              <icon-close size="18" />
            </v-btn>
          </div>
          <form>
            <div class="row-details current-password">
              <p class="label">
                {{ $t('langkey.your-current-password') }} :
              </p>
              <div class="right">
                <v-text-field
                  v-model="oldPassword"
                  type="password"
                  :class="[
                    isErrorOldPasswordInput ? 'show-error' : null
                  ]"
                  :label="`${$t('langkey.enter-current-password')}`"
                  solo
                  hide-details="auto"
                />
                <a @click="onClickForgotPassword">{{ $t('langkey.forgot-password') }}</a>
              </div>
            </div>
            <div class="validate-info">
              <div class="row-info">
                <icon-validate /> {{ $t('langkey.new-password-length') }}
              </div>
              <div class="row-info">
                <icon-validate /> {{ $t('langkey.password-contain') }} :<br>
                {{ $t('langkey.password-characters') }}
              </div>
            </div>
            <div class="row-details">
              <p class="label">
                {{ $t('langkey.new-password') }} :
              </p>
              <v-text-field
                v-model="password"
                type="password"
                :class="[
                  isErrorPasswordInput ? 'show-error' : null
                ]"
                :label="`${$t('langkey.password-length')}`"
                solo
                hide-details="auto"
              />
            </div>
            <div class="row-details repeat-new-password">
              <p class="label">
                {{ $t('langkey.repeat-new-password') }} :
              </p>
              <v-text-field
                v-model="confirmPassword"
                type="password"
                :class="[
                  isErrorConfirmPasswordInput ? 'show-error' : null
                ]"
                :label="`${$t('langkey.reenter-new-password')}`"
                solo
                hide-details="auto"
              />
            </div>
            <div class="d-flex align-center">
              <v-btn
                outlined
                plain
                @click="onSavePassword"
              >
                {{ $t('langkey.save-password') }}
              </v-btn>
              <transition name="fade">
                <div
                  v-if="errorTexts.length > 0"
                  class="error-texts"
                >
                  <p
                    v-for="(error, index) in errorTexts"
                    :key="`errorText${index}`"
                  >
                    <span v-html="$t(error.text)" />
                  </p>
                </div>
              </transition>
            </div>
          </form>
        </div>
      </v-card>
    </v-container>

    <forgot-password-dialog
      ref="forgotPasswordDialog"
      @on-email-sent="onEmailSent"
    />

    <v-dialog
      key="recoveryEmailSentDialog"
      v-model="recoveryEmailSentDialog"
      max-width="330"
    >
      <v-card>
        <v-card-title />
        <v-card-text>
          {{ $t('langkey.recovery-email-sent') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="recoveryEmailSentDialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<style lang="scss">
@import "@/styles/pages/admin-account-info.scss";
</style>

<script>
import IconEdit from '@/components/icons/IconEdit';
import IconValidate from '@/components/icons/IconValidate';
import ForgotPasswordDialog from '@/components/ForgotPasswordDialog';
import { mapState } from 'vuex';

export default {
  components: {
    IconEdit,
    IconValidate,
    ForgotPasswordDialog,
  },
  data() {
    return {
      recoveryEmailSentDialog: false,
      oldPassword: '',
      password: '',
      confirmPassword: '',
      checkPasswordCount: 0,
      errorTexts: [],
      errorAtLeast: false,
      errorCase: false,
    };
  },
  computed: {
    ...mapState('user', ['userInformation']),
    hasAtLeastEightCharacters() {
      return this.password.length >= 8;
    },
    hasUppercase() {
      return /[A-Z]/.test(this.password);
    },
    hasLowerCase() {
      return /[a-z]/.test(this.password);
    },
    hasNumeric() {
      return /[0-9]/.test(this.password);
    },
    hasSpecialCharacter() {
      // eslint-disable-next-line
      return /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(this.password)
    },
    isPasswordAndConfirmPasswordEmpty() {
      return this.password.length === 0 && this.confirmPassword.length === 0;
    },
    isPasswordAndConfirmPasswordNotSame() {
      return (this.password.length > 0 || this.confirmPassword.length > 0)
        && this.password !== this.confirmPassword;
    },
    isErrorOldPasswordInput() {
      if (this.errorTexts.length === 0) {
        return false;
      }
      return this.errorTexts.find((item) => item.key === 'oldPassword');
    },
    isErrorPasswordInput() {
      if (this.errorTexts.length === 0) {
        return false;
      }
      return this.errorTexts.find((item) => item.key === 'password');
    },
    isErrorConfirmPasswordInput() {
      if (this.errorTexts.length === 0) {
        return false;
      }
      return this.errorTexts.find((item) => item.key === 'confirmPassword');
    },
  },
  created() {
    if (this.userInformation === null) {
      this.$store.dispatch('user/getUserInformation');
    }
  },
  methods: {
    onClickForgotPassword() {
      this.$refs.forgotPasswordDialog.show();
    },
    onEmailSent() {
      this.recoveryEmailSentDialog = true;
    },
    validateInput() {
      this.checkPasswordCount = 0;
      this.errorAtLeast = false;
      this.errorCase = false;
      this.errorTexts = [];

      if (this.oldPassword === '' && this.isPasswordAndConfirmPasswordEmpty) {
        this.errorTexts = [
          {
            key: 'oldPassword',
            text: this.$t('langkey.please-input-old-password'),
          },
          {
            key: 'password',
            text: this.$t('langkey.please-input-password'),
          },
          {
            key: 'confirmPassword',
            text: this.$t('langkey.please-input-confirm-password'),
          },
        ];
        return;
      }

      if (this.oldPassword === '') {
        this.errorTexts = [
          {
            key: 'oldPassword',
            text: this.$t('langkey.please-input-old-password'),
          },
        ];
        return;
      }

      if (this.isPasswordAndConfirmPasswordEmpty) {
        this.errorTexts = [
          {
            key: 'password',
            text: this.$t('langkey.please-input-password'),
          },
          {
            key: 'confirmPassword',
            text: this.$t('langkey.please-input-confirm-password'),
          },
        ];
        return;
      }

      if (this.isPasswordAndConfirmPasswordNotSame) {
        this.errorTexts = [
          {
            key: 'password',
            text: this.$t('langkey.password-not-same'),
          },
        ];
        return;
      }

      if (!this.hasAtLeastEightCharacters) {
        this.errorAtLeast = true;
        this.errorTexts = [
          {
            key: 'password',
            text: this.$t('langkey.wrong-password-case'),
          },
        ];
        return;
      }

      if (this.hasUppercase) {
        this.checkPasswordCount++;
      }
      if (this.hasLowerCase) {
        this.checkPasswordCount++;
      }
      if (this.hasNumeric) {
        this.checkPasswordCount++;
      }
      if (this.hasSpecialCharacter) {
        this.checkPasswordCount++;
      }

      if (this.checkPasswordCount < 3) {
        this.errorTexts = [
          {
            key: 'password',
            text: this.$t('langkey.wrong-password-case'),
          },
        ];
        this.errorCase = true;
      }
    },
    onSavePassword() {
      this.validateInput();
      if (this.errorTexts.length > 0) {
        return;
      }

      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });

      this.$store.dispatch('user/confirmPasswordChange', {
        password_current: this.oldPassword,
        password_new: this.password,
      })
        .then(() => {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: this.$t('langkey.password-change-success'),
            message: '',
          })
            .then(() => {
              this.oldPassword = '';
              this.password = '';
              this.confirmPassword = '';
            });
        })
        .catch((err) => {
          this.$store.dispatch('dialog/setShowErrorDialog', {
            status: true,
            title: this.$t('langkey.confirm-password-error'),
            message: err,
          });
        })
        .finally(() => {
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
        });
    },
  },
};
</script>