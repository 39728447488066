var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"admin-account-info"},[_c('v-container',{staticClass:"custom-container"},[_c('div',{staticClass:"textbox"},[_c('h1',[_vm._v(_vm._s(_vm.$t('langkey.account-information')))]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$t('langkey.admin-account-info-description'))+" ")])]),_c('v-card',{staticClass:"account-info",attrs:{"rounded":"sm"}},[_c('div',{staticClass:"user-information"},[_c('div',{staticClass:"title-header"},[_c('h6',[_vm._v(_vm._s(_vm.$t('langkey.user-information')))]),_c('v-btn',{attrs:{"icon":"","plain":""}},[_c('icon-edit')],1)],1),_c('div',{staticClass:"row-details"},[_c('p',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('langkey.email'))+" : ")]),(_vm.userInformation)?_c('p',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.userInformation.email)+" ")]):_vm._e()]),_c('div',{staticClass:"row-details"},[_c('p',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('langkey.username'))+" : ")]),(_vm.userInformation)?_c('p',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.userInformation.username)+" ")]):_vm._e()])]),_c('div',{staticClass:"change-password"},[_c('div',{staticClass:"title-header"},[_c('h6',[_vm._v(_vm._s(_vm.$t('langkey.change-password')))]),_c('v-btn',{attrs:{"icon":"","plain":""}},[_c('icon-close',{attrs:{"size":"18"}})],1)],1),_c('form',[_c('div',{staticClass:"row-details current-password"},[_c('p',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('langkey.your-current-password'))+" : ")]),_c('div',{staticClass:"right"},[_c('v-text-field',{class:[
                  _vm.isErrorOldPasswordInput ? 'show-error' : null
                ],attrs:{"type":"password","label":`${_vm.$t('langkey.enter-current-password')}`,"solo":"","hide-details":"auto"},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}),_c('a',{on:{"click":_vm.onClickForgotPassword}},[_vm._v(_vm._s(_vm.$t('langkey.forgot-password')))])],1)]),_c('div',{staticClass:"validate-info"},[_c('div',{staticClass:"row-info"},[_c('icon-validate'),_vm._v(" "+_vm._s(_vm.$t('langkey.new-password-length'))+" ")],1),_c('div',{staticClass:"row-info"},[_c('icon-validate'),_vm._v(" "+_vm._s(_vm.$t('langkey.password-contain'))+" :"),_c('br'),_vm._v(" "+_vm._s(_vm.$t('langkey.password-characters'))+" ")],1)]),_c('div',{staticClass:"row-details"},[_c('p',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('langkey.new-password'))+" : ")]),_c('v-text-field',{class:[
                _vm.isErrorPasswordInput ? 'show-error' : null
              ],attrs:{"type":"password","label":`${_vm.$t('langkey.password-length')}`,"solo":"","hide-details":"auto"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('div',{staticClass:"row-details repeat-new-password"},[_c('p',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('langkey.repeat-new-password'))+" : ")]),_c('v-text-field',{class:[
                _vm.isErrorConfirmPasswordInput ? 'show-error' : null
              ],attrs:{"type":"password","label":`${_vm.$t('langkey.reenter-new-password')}`,"solo":"","hide-details":"auto"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"outlined":"","plain":""},on:{"click":_vm.onSavePassword}},[_vm._v(" "+_vm._s(_vm.$t('langkey.save-password'))+" ")]),_c('transition',{attrs:{"name":"fade"}},[(_vm.errorTexts.length > 0)?_c('div',{staticClass:"error-texts"},_vm._l((_vm.errorTexts),function(error,index){return _c('p',{key:`errorText${index}`},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t(error.text))}})])}),0):_vm._e()])],1)])])])],1),_c('forgot-password-dialog',{ref:"forgotPasswordDialog",on:{"on-email-sent":_vm.onEmailSent}}),_c('v-dialog',{key:"recoveryEmailSentDialog",attrs:{"max-width":"330"},model:{value:(_vm.recoveryEmailSentDialog),callback:function ($$v) {_vm.recoveryEmailSentDialog=$$v},expression:"recoveryEmailSentDialog"}},[_c('v-card',[_c('v-card-title'),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('langkey.recovery-email-sent'))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.recoveryEmailSentDialog = false}}},[_vm._v(" OK ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }